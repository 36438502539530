import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

// NOTE: テスト実行時の notifyReleaseStages 警告が邪魔だからログ無効化
const options = import.meta.env.RAILS_ENV === 'test' ? { logger: null } : {};

const getUserOptions = () => {
  if (!window.APP.rails.user) return {};

  const { id, email } = window.APP.rails.user;
  return {
    user: { id, email },
  };
};

window.Bugsnag = Bugsnag.start({
  apiKey: 'f59f872a3ac8204c38e5e72836562674',
  releaseStage: import.meta.env.RAILS_ENV,
  enabledReleaseStages: ['staging', 'production'],
  plugins: [new BugsnagPluginVue()],
  ...getUserOptions(),
  ...options,
});
